import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./styles/checkout.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const Checkout = ({ removeFromCart }) => {
    const navigate = useNavigate();

    const [orderItems, setOrderItems] = useState([]);
    const [modifiedOrderDetails, setModifiedOrderDetails] = useState([]);
    const [order, setOrder] = useState({
        name: "",
        phone: "",
        address_1: "",
        address_2: "",
        note: "",
        products: JSON.parse(localStorage.getItem("cart")).map(item => ({
          id: item.dish.id,
          amount: item.quantity
      }))
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setOrder({ ...order, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', order.name);
        formData.append('phone', order.phone);
        formData.append('address_1', order.address_1);
        formData.append('address_2', order.address_2);
        formData.append('note', order.note);
        formData.append('products', JSON.stringify(order.products));
        try {
            const response = await axios.post('https://back.eltaqwarestaurant.com/user/make_order', formData);
            console.log(formData)
            console.log(response.data); // Handle success response
            toast.success("تم ارسال الطلب بنجاح")
            setOrder({
                name: "",
                phone: "",
                address_1: "",
                address_2: "",
                note: "",
            })
            navigate("/first/check/order")
            localStorage.clear()
        } catch (error) {
          console.log(formData)
            console.error('Error submitting form:', error); // Handle error
            toast.error(error.response.data.message)
        }
    };

    
    useEffect(() => {
        setOrderItems(JSON.parse(localStorage.getItem("cart")))
    }, []);

    const handleRemoveItem = (index, e) => {
        e.preventDefault();
        removeFromCart(index);
    };

    const totalPrice = orderItems.reduce((total, orderItem) => {
        return total + (orderItem.quantity * parseInt(orderItem.dish.final_price));
    }, 0);

    return (
        <div className='checkout'>
            <div className='checkout_container'>
                <h1 className='checkout_title'>طلباتي</h1>
                <div className='checkout_list'>
                    {orderItems.map((orderItem, index) => (
                        <div className='cart_order' key={index}>
                            <div className='order_info'>
                            <div className='cart_order_img_holder'>
                                <img src={`https://back.eltaqwarestaurant.com/images/${orderItem.dish.image}`} alt={orderItem.dish.title} />
                            </div>
                            <p className='cart_order_quantity'>x{orderItem.quantity}</p>
                            <p className='cart_order_title'>{orderItem.dish.title.length > 20 ? `...${orderItem.dish.title.substring(0, 20)}` : orderItem.dish.title}</p>
                            <h6 className='cart_order_price'>{orderItem.quantity * parseInt(orderItem.dish.final_price)} EGP</h6>
                            </div>

                            <button className='remove_from_cart' onClick={(e) => handleRemoveItem(index, e)}><i className="fa-solid fa-minus"></i></button>
                        </div>
                    ))}
                    <div className='cart_orders_total checkout_total'>
                        <h1>الاجمالي</h1>
                        <p>{totalPrice} EGP</p> {/* Display total price here */}
                    </div>
                </div>
                <form className='checkout_form' onSubmit={handleSubmit}>
                    <div className="create_form_container">
                        <h1 className='checkout_title'>معلومات شخصية</h1>
                        <div className="input_holder">
                            <label>اسم صاحب الطلب</label>
                            <input
                                className="input_input"
                                placeholder="أدخل الاسم"
                                name="name"
                                value={order.name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="input_holder">
                            <label>رقم الهاتف</label>
                            <input
                                className="input_input"
                                placeholder="أدخل الرقم"
                                name="phone"
                                value={order.phone}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="input_holder">
                            <label>عنوان 1</label>
                            <input
                                className="input_input"
                                placeholder="أدخل عنوانك"
                                name="address_1"
                                value={order.address_1}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="input_holder">
                            <label>عنوان 2</label>
                            <input
                                className="input_input"
                                placeholder="أدخل تفاصيل العنوان"
                                name="address_2"
                                value={order.address_2}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="input_holder">
                            <label>ملحوظة</label>
                            <textarea
                                className="input_input"
                                placeholder="أدخل ملحوظة علي الأوردر"
                                name="note"
                                value={order.note}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <input type="submit" className="input_submit" value="أطلب الآن" />
                    </div>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Checkout;
